import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import NavBar from './NavBar';
import HamburgerMenu from './HamburgerMenu';
import { DisplayToggle } from '../styles/DisplayToggle';
import { Logo } from '../common/Logo';

const Header = (props) => {
  return (
    <>
      {/* <TopBarHeader /> */}
      <Container>
        <DisplayToggle>
          <Logo />
          <NavBar />
        </DisplayToggle>
      </Container>
      <HamburgerMenu />
    </>
  );
};

export default Header;
