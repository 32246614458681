import React from 'react';
import styled from 'styled-components';
import { device } from '../../theme';

const MenuContainer = styled.div`
  display: none;
  @media ${device.laptop} {
    padding: 3rem 0;
    display: block;
  }
`;

const FooterMenuBar = styled.ul`
  list-style: none;
  text-align: center;
  margin-bottom: 0;
  padding-left: 0;
`;

const FooterMenuItems = styled.li`
  display: inline-block;
  margin: 0 29px;
`;

const FooterMenuItemsLink = styled.a`
  color: #032535;
  :hover {
    color: #f5be58;
  }
`;

const FooterMenu = (props) => {
  return (
    <>
      <MenuContainer>
        <FooterMenuBar>
          <FooterMenuItems>
            <FooterMenuItemsLink href="/">Home</FooterMenuItemsLink>
          </FooterMenuItems>
          <FooterMenuItems>
            <FooterMenuItemsLink href="#">Company</FooterMenuItemsLink>
          </FooterMenuItems>
          <FooterMenuItems>
            <FooterMenuItemsLink href="#">Services</FooterMenuItemsLink>
          </FooterMenuItems>
          <FooterMenuItems>
            <FooterMenuItemsLink href="#">Projects</FooterMenuItemsLink>
          </FooterMenuItems>
          <FooterMenuItems>
            <FooterMenuItemsLink href="/contact">Contacts</FooterMenuItemsLink>
          </FooterMenuItems>
        </FooterMenuBar>
      </MenuContainer>
    </>
  );
};

export default FooterMenu;
