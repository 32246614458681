import styled from 'styled-components';
import { device } from '../../theme';

const DisplayToggle = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export { DisplayToggle };
