import React, { useState } from 'react';
import styled from 'styled-components';
import { device } from '../../theme';
import { colors } from '../../theme/constants';
import { Link } from 'gatsby';

const MenuList = styled.li`
  display: block;
  align-items: center;
  justify-content: space-between;
  margin: 0 2rem;
  position: relative;
  height: 100%;
  border-bottom: ${(props) => (props.underline ? '1px solid #e5e5e5' : 'none')};
  :hover {
    ::after {
      height: 0.3rem;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${colors.yellow.mainDarkYellow};
      content: '';
      display: block;
      transition: all 0.3s linear;
      z-index: 999;
    }
  }
  @media ${device.laptop} {
    display: flex;
  }
`;

const MenuItems = styled(Link)`
  display: flex;
  flex: 1;
  justify-content: ${(props) => (props.left ? 'space-between' : 'center')};
  align-items: center;
  padding: 1.1rem 0;
  text-decoration: none;
  text-align: center;
  outline: none;
  color: ${colors.blue.textBlue};
  white-space: nowrap;
  transition: all 0.2s ease-out 0s;
  font-weight: 700;
  text-transform: none;
  cursor: pointer;
  :hover {
    color: ${colors.gray.pTagsColor};
  }

  @media ${device.laptop} {
    padding: 3.1rem 0;
  }
`;

const ItemIcon = styled.span`
  display: ${(props) => (props.withIcon ? 'flex' : 'none')};
  color: ${colors.blue.textBlue};
  cursor: pointer;
  svg,
  img {
    width: 2rem;
    height: 2rem;
  }
`;

const SubMenu = styled.ul`
  white-space: nowrap;
  padding: 1.5rem 0;
  position: relative;
  transition: all 0.3s ease-in-out;
  margin: 0 0;
  background-color: #fff;
  display: block;
  border: 1px solid ${colors.gray.mainLightGray};

  @media ${device.laptop} {
    padding: 2.5rem 0;
    transform: translateY(3rem);
    display: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
    position: absolute;
    top: 6.7rem;
    left: -4rem;
    box-shadow: 1.5rem 1.5rem 3.8rem 0 rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
`;

const HeaderSubMenu = (props) => {
  const [isHover, setIsHover] = useState(false);

  const handleOpen = (e) => {
    e.preventDefault();
    setIsHover(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setIsHover(false);
  };

  const toggleMenu = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    setIsHover(!isHover);
  };

  return props.link ? (
    <MenuList underline={props.underline}>
      <MenuItems
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        onClick={toggleMenu}
        left={props.left}
        to={props.link}
      >
        {props.title}
        {props.children && (
          <ItemIcon withIcon>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="sort-down"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"
              ></path>
            </svg>
          </ItemIcon>
        )}
      </MenuItems>
      {props.children && isHover && (
        <SubMenu isOpen={isHover}>{props.children}</SubMenu>
      )}
    </MenuList>
  ) : null;
};

export default HeaderSubMenu;
