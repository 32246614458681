import styled from 'styled-components';
import { device } from './constants';

const Cell = styled.div`
  position: relative;
  height: ${(props) => (props.cellHeight ? props.cellHeight : '100%;')};
  ${(props) => props.cellHeight && `overflow: auto;`}
  min-width: 0;
  grid-column-end: ${({ width = 1 }) => `span ${width};`};
  grid-row-end: ${({ height = 1 }) => `span ${height};`};
  ${({ left }) => left && `grid-column-start: ${left};`}
  ${({ top }) => top && `grid-row-start: ${top};`}
  ${({ center }) => center && `text-align: center;`}
  ${({ area }) => area && `grid-area: ${area};`}
  ${
    /* prettier-ignore */
    ({ middle }) => middle && `
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-self: stretch;
  `
  }

  @media ${device.tablet} {
    ${({ order }) => order && `order: ${order};`}
  }
`;

// interface CellProps {
//   className?: string;
//   order?: number;
//   cellHeight?: string;
//   width?: number;
//   height?: number;
//   top?: number | string;
//   left?: number | string;
//   middle?: boolean;
//   center?: boolean;
//   area?: string;
// }

export { Cell };
