import React from 'react';
import { Helmet } from 'react-helmet';
import { GlobalStyle } from '../theme/GlobalStyle';
import Header from './header/Header';
import Footer from './footer/Footer';

const TemplateWrapper = ({ children }) => (
  <>
    <GlobalStyle />
    <Helmet title="Home | Softiware AS Official Website" />
    <Header />
    {children}
    <Footer />
  </>
);

export default TemplateWrapper;
