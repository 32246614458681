import { createGlobalStyle } from 'styled-components';

import { reset } from './Reset';
import { device } from './constants';

const GlobalStyle = createGlobalStyle`
 ${reset}
 html {
   font-size: 62.5%; /* 62.5% of 16px = 10px */
   font-family:'Poppins', Tahoma, Geneva, Verdana, sans-serif;
 }

 body,p,div,span,main,header, li,a, strong, b {
   font-size: 1.3rem;
   line-height: 1.8;
   
    @media ${device.tablet} {
      font-size: 1.7rem;
      line-height: 2;
    }
    
 }
 strong, b{
   font-weight: 700;
 }
`;

export { GlobalStyle };
