import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { LINKS } from '../../routes';
import { device, colors } from '../../theme';
import { FetchSvg } from '../common/FetchSvg';

const SiteLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  /* padding-top: 3rem; */

  :visited {
    color: #7141b1;
  }

  img {
    max-height: 4rem;
  }
  @media ${device.mobileM} {
    img {
      max-height: 5rem;
    }
  }
  @media ${device.tablet} {
    img {
      max-height: 7rem;
    }
  }
`;

const Title = styled.p`
  font-size: 3rem;
  padding-left: 2rem;
  font-weight: 400;
  color: ${colors.blue.mainBlue};
  position: relative;
  display: inline-block;
  margin-bottom: 2px;
  text-transform: uppercase;
  @media ${device.tablet} {
    font-weight: 800;
  }
`;

const Logo = (props) => {
  return (
    <SiteLink to={LINKS.home}>
      <img src="/logo.webp" alt="Softiware AS logo" />
    </SiteLink>
  );
};

export { Logo };
