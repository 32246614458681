import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/constants';
import { FetchSvg } from '../common/FetchSvg';

const WidgetWrapper = styled.div`
  display: flex;
  justify-content: center;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  font-size: 0;
  padding: 3rem 0 7rem;
`;

const SocialLink = styled.a`
  /* background-color: ${colors.white}; */
  border-radius: 50%;
  box-shadow: none;
  text-decoration: none;
  padding: 0.5em;
  margin-right: 1rem;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s;
  line-height: 1;
  font-size: 1.2rem;
  hyphens: manual;
  :hover {
    transform: translateY(-8px);
  }
`;

const SocialLinkIcon = styled.span`
  svg,
  img {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
  }
`;

const WidgetItem = (props) => {
  return (
    <SocialLink target="_blank">
      <SocialLinkIcon>
        <FetchSvg name={props.iconName} />
      </SocialLinkIcon>
    </SocialLink>
  );
};

const FooterWidget = (props) => {
  return (
    <WidgetWrapper>
      <WidgetItem href="#" iconName="twitter" />
      <WidgetItem href="#" iconName="facebook-f" />
      <WidgetItem href="#" iconName="linkedin-in" />
    </WidgetWrapper>
  );
};

export default FooterWidget;
