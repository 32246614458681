import React, { useState } from 'react';
import styled from 'styled-components';
import { device } from '../../theme';
import NavBar from './NavBar';
import { Logo } from '../common/Logo';

const NavbarContainer = styled.div`
  max-width: 780px;
  margin: auto;
  margin: 2rem;
  display: block;
  @media ${device.tablet} {
    display: none;
  }
`;
const TopNav = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ToggleBtn = styled.button`
  background: #fff;
  border: none;
  outline: none;
`;

const HamBarIcon = styled.span`
  svg,
  img {
    width: 2rem;
    height: auto;
    display: flex;
    color: #032535;
  }
`;

const HamburgerMenu = (props) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = (e) => {
    setOpen(!isOpen);
  };

  return (
    <NavbarContainer>
      <TopNav>
        <Logo />
        <ToggleBtn onClick={toggle}>
          <HamBarIcon>
            {!isOpen ? (
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="bars"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                ></path>
              </svg>
            ) : (
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            )}
          </HamBarIcon>
        </ToggleBtn>
      </TopNav>
      {isOpen && <NavBar vertical left underline />}
    </NavbarContainer>
  );
};

export default HamburgerMenu;
