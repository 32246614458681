import React from 'react';
import styled from 'styled-components';
import HeaderSubMenu from './HeaderSubMenu';
import { colors } from '../../theme/constants';
import { device } from '../../theme';

const MainNavigation = styled.nav`
  position: relative;
  height: 100%;
  width: auto;
`;

const PrimaryMenu = styled.ul`
  font-weight: 700;
  list-style: none;
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  width: 100%;
  height: 100%;
`;

const SubMenuItems = styled.li`
  position: relative;
  padding: 0 2rem;
  color: #58455f;
  line-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.laptop} {
    padding: 0 4rem;
  }
`;

const SubMenuItemsLink = styled.a`
  text-decoration: none;
  outline: none;
  /* color: #58455f; */
  white-space: nowrap;
  /* transition: all 0.2s ease-out 0s; */
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
  line-height: 30px;
  color: ${colors.blue.textBlue};
  text-align: left;
  display: block;
  padding: 5px 0 5px 0;
  position: relative;
  text-transform: none;
  transition: all 0.3s linear;
  :hover {
    color: ${colors.blue.mainBlue};
  }
`;

const NavBar = (props) => {
  return (
    <MainNavigation>
      <PrimaryMenu vertical={props.vertical}>
        <HeaderSubMenu
          title={'Home'}
          left={props.left}
          underline={props.underline}
          link={'/'}
        />
        <HeaderSubMenu
          title={'Company'}
          left={props.left}
          underline={props.underline}
          footerNav={props.footerNav}
        >
          <>
            <SubMenuItems>
              <SubMenuItemsLink href="#">About us</SubMenuItemsLink>
            </SubMenuItems>
            <SubMenuItems>
              <SubMenuItemsLink href="#">Why choose us</SubMenuItemsLink>
            </SubMenuItems>
            <SubMenuItems>
              <SubMenuItemsLink href="#">Our team</SubMenuItemsLink>
            </SubMenuItems>
            <SubMenuItems>
              <SubMenuItemsLink href="#">FAQS</SubMenuItemsLink>
            </SubMenuItems>
          </>
        </HeaderSubMenu>
        <HeaderSubMenu
          title={'Services'}
          left={props.left}
          underline={props.underline}
          footerNav={props.footerNav}
        >
          <>
            <SubMenuItems>
              <SubMenuItemsLink href="#">Web Development</SubMenuItemsLink>
            </SubMenuItems>
            <SubMenuItems>
              <SubMenuItemsLink href="#">
                Mobile App Development
              </SubMenuItemsLink>
            </SubMenuItems>
            <SubMenuItems>
              <SubMenuItemsLink href="#">IT Consulting</SubMenuItemsLink>
            </SubMenuItems>
            <SubMenuItems>
              <SubMenuItemsLink href="#">IT Workshops</SubMenuItemsLink>
            </SubMenuItems>
          </>
        </HeaderSubMenu>
        <HeaderSubMenu
          title={'Projects'}
          left={props.left}
          underline={props.underline}
          footerNav={props.footerNav}
        />
        <HeaderSubMenu title={'Contacts'} left={props.left} link={'/contact'} />
      </PrimaryMenu>
    </MainNavigation>
  );
};

export default NavBar;
