import styled from 'styled-components';
import { device } from '../../theme';

const SectionWrapper = styled.section`
  /* background-color: ${(props) =>
    props.darkBlue ? '#211e3b' : '#F8F8F8'}; */
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'white')};
  background-image: url(${(props) => props.bgUrl});
  background-position: center left;
  background-repeat: ${(props) => (props.repeatX ? 'repeat-x' : 'no-repeat')};
  display: ${(props) => (props.hideSection ? 'none' : 'block')};
  /* padding: 3rem 0; */
  padding: ${(props) => (props.noResponsivePadding ? '0' : '3rem 0')};
  position: relative;
  @media ${device.laptop} {
    /* padding: 11rem 0; */
    padding: ${(props) => (props.noPadding ? '0' : '11rem 0')};
    display: ${(props) => (props.hideSection ? 'block' : 'block')};
  }
`;

export { SectionWrapper };
