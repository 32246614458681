import React, { useEffect, useState } from 'react';

const FetchSvg = ({ name }) => {
  const [svg, setSvg] = useState();
  // this runs when first time component render
  useEffect(() => {
    // fetch from server
    fetch(`/icons/${name}.svg`)
      .then((res) => res.text())
      .then((content) => {
        setSvg(content);
      });
  }, [name]);

  return <span dangerouslySetInnerHTML={{ __html: svg }} />;
};

export { FetchSvg };
