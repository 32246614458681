import styled from 'styled-components';
import { device } from './constants';

const autoRows = ({ minRowHeight = '2rem' }) =>
  `minmax(${minRowHeight}, auto);`;

const frGetter = (value) => {
  return typeof value === 'number' ? `repeat(${value}, 1fr);` : `${value};`;
  // return `repeat(auto-fit, minmax(var(${value}), 1fr))`;
};

const gap = ({ gap = '1.5rem' }) => gap;

const flow = ({ flow = 'row' }) => flow;

const formatAreas = (areas) => areas.map((area) => `"${area}"`).join(' ');

const Grid = styled.div`
  display: grid;
  position: relative;
  height: ${({ height = 'auto' }) => height};
  ${({ rowGap }) => rowGap && `row-gap: ${rowGap};`}

  @media ${device.tablet} {
    grid-auto-flow: ${flow};
    grid-gap: ${gap};
    grid-auto-rows: ${autoRows};
    ${({ rows }) => rows && `grid-template-rows: ${frGetter(rows)};`}
    grid-template-columns: ${({ columns = 12 }) => frGetter(columns)};
    ${({ columnGap }) => columnGap && `column-gap: ${columnGap};`}
    ${({ areas }) => areas && `grid-template-areas: ${formatAreas(areas)};`}
    ${({ justifyContent }) =>
      justifyContent && `justify-content: ${justifyContent};`}
    ${({ alignContent }) => alignContent && `align-content: ${alignContent};`}
  }
`;

// interface GridProps {
//   className?: string;
//   columns?: string | number;
//   gap?: string;
//   columnGap?: string;
//   rowGap?: string;
//   height?: string;
//   minRowHeight?: string;
//   flow?: string;
//   rows?: string | number;
//   areas?: string[];
//   justifyContent?: string;
//   alignContent?: string;
// }

export { Grid };
