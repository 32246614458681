import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import { SectionWrapper } from '../common/SectionWrapper';
import { Grid, Cell } from '../../theme';
import styled from 'styled-components';
import FooterMenu from './FooterMenu';
import FooterWidget from './FooterWidget';
import BackToTop from './BackToTop';
import { BgImgWrapper } from '../common/BgImgDiv';
import { colors } from '../../theme/constants';
import { FetchSvg } from '../common/FetchSvg';
import { device } from '../../theme';

const ContactInfoWrapper = styled.div`
  padding: 7rem 0;
`;

const ContactInfoIcon = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  svg,
  img {
    width: 2.5rem;
    height: 2.5rem;
    color: ${colors.yellow.mainDarkYellow};
  }
  @media ${device.laptop} {
    svg,
    img {
      width: 3rem;
      height: 3rem;
    }
  }
`;

const ContactInoText = styled.p`
  text-align: center;
  font-size: 1.7rem;
  color: ${colors.blue.mainBlue};
  /* padding-top: 1.5rem; */
  @media ${device.laptop} {
    font-size: 2.2rem;
  }
`;

const FooterText = styled.p`
  text-align: center;
  color: ${colors.blue.mainBlue};
`;

const FooterItem = (props) => {
  return (
    <Cell>
      <ContactInfoIcon>
        <FetchSvg name={props.iconName} />
      </ContactInfoIcon>
      <ContactInoText>{props.subtitle}</ContactInoText>
      <ContactInoText>{props.title}</ContactInoText>
    </Cell>
  );
};

const Footer = (props) => {
  const year = new Date().getFullYear();

  return (
    <>
      <SectionWrapper
        noPadding
        noResponsivePadding
        bgColor={colors.gray.mainLightGray}
        // bgUrl={'/images/Footer5.png'}
      >
        {/* <BgImgWrapper bgUrl={'/images/bg-footer1.png'} /> */}
        <Container>
          <ContactInfoWrapper>
            <Grid rowGap={'3.5rem'} columnGap={'3.5rem'} rows={1} columns={3}>
              <FooterItem
                subtitle="Softiware, Oslo, Norway"
                title="Our Address"
                iconName="globe"
              />
              <FooterItem
                subtitle="support@saftiware.com"
                title="Our Mailbox"
                iconName="envelop"
              />
              <FooterItem
                subtitle="+47 - 48 63 46 22"
                title="Our Phone"
                iconName="phone-alt"
              />
            </Grid>
          </ContactInfoWrapper>
          <FooterMenu />
          <FooterText>© {year} Softiware. All Rights Reserved</FooterText>
          <FooterWidget />
        </Container>
        <BackToTop />
      </SectionWrapper>
    </>
  );
};

export default Footer;
