import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/constants';

const BackTopLink = styled.button`
  background: ${colors.yellow.mainDarkYellow};
  color: ${colors.blue.textBlue};
  border: 0 none;
  border-radius: 2px;
  cursor: pointer;
  width: 4.2rem;
  height: 4.5rem;
  line-height: 4.5rem;
  outline: medium none;
  position: fixed;
  right: 4rem;
  bottom: 5rem;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-out 0s;
  z-index: 1000;
  :hover {
    transform: translateY(-8px);
  }
`;

const BackTopIcon = styled.span`
  svg,
  img {
    width: 1em;
    height: 1em;
  }
`;

const BackToTop = (props) => {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', (e) => {
      toggleVisibility();
    });
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = (e) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {isVisible && (
        <BackTopLink onClick={scrollToTop}>
          <BackTopIcon>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="arrow-up"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
              ></path>
            </svg>
          </BackTopIcon>
        </BackTopLink>
      )}
    </>
  );
};

export default BackToTop;
